


@media (min-width:576px) and (max-width:767px){
    .reviews{
        width:50%;
        height:10vh;

    }
}
@media (min-width:768px) and (max-width:992px){
    .reviews{
        width:100%;
        height:24vh;
    
    }
}
@media (min-width:992px) and (max-width:1600px){
    .reviews{
        width:100%;
        height:52vh;
    
    }
}