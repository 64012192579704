
.title{
    font-size:90px;
    text-align: left;
    align-items:left;
    color:aliceblue;

}
.mover{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.images-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.gallery-image{
    margin:10px;
}
.gallery-heading{
    color:#EB4258;
}
h1{
    font-weight:bold;

}
.section-title span{
    font-size: 30px;
    font-weight:bold;
}
.pic{
    background:linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.7));
}
.gallery-images{
    display:flex;
    justify-content: center;
    align-items: center;
}
.motion{
    justify-content: center;
    align-items: center;
}
.whatsapp{
    position:fixed;
    background:#128c7e;
    bottom: 70px;
    right: 8px;
    padding-top: 10px;
    
    margin:17px;
    border-radius:25px;

}
.carousel-control-prev{
    display: none !important;;
}
.carousel-control-next{
    display: none !important;;
}
.gaps{
    width: 600px,!important;
    display:flex;
    justify-content: center;
    margin: 10px!important;
    
}
.slick-slide{
    width: 300px !important;
    height: 500px;
    margin-right:60px;
    margin-left: 60px;
}
.reserve{
    color:white;
}
.color{
    color: #EB4258;
}
.color:hover{
    color:#EB4258;
}
.copyright-container{
    overflow: hidden;
}
.navbar{
    margin-bottom: -15px;
}
