.carousel-indicators{
    display:none;
}
#gallery .carousel-item{
    padding-left:23%;
}
@media (min-width:992px) and (max-width:1250px){
    #gallery .carousel-item{
        padding-left:26%;
        position:sticky;
    }
    
}
@media (min-width:1250px) and (max-width:1500px){
    #gallery .carousel-item{
        padding-left:30%;
        position:sticky;
    }
    
}
@media (min-width:1500px) and (max-width:2000px){
    #gallery .carousel-item{
        padding-left:35%;
        position:sticky;
    }
    
}
